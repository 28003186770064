<template>
    <div class="partners">
        <Splide :options="options">
            <SplideSlide v-for="item in partners" :key="item">
                <img :src="item.logo" :alt="item.name">
            </SplideSlide>
        </Splide>
    </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import Sumsub from '@/assets/img/icons/sumsub.png'
import Pay24 from '@/assets/img/icons/pay24.png'
import Kraken from '@/assets/img/icons/kraken.svg'
import Bybit from '@/assets/img/icons/bybit.svg'
import Binance from '@/assets/img/icons/binance.svg'
import Bitfinex from '@/assets/img/icons/bitfinex.svg'

export default {
    data() {
        return {
            partners: [
                {
                    name: 'Sumsub',
                    logo: Sumsub
                },
                {
                    name: 'Pay24',
                    logo: Pay24
                },
                {
                    name: 'Kraken',
                    logo: Kraken
                },
                {
                    name: 'Bybit',
                    logo: Bybit
                },
                {
                    name: 'Binance',
                    logo: Binance
                },
                {
                    name: 'Bitfinex',
                    logo: Bitfinex
                }
            ],
            options: {
                type: 'loop',
                autoWidth: true,
                gap: '5%',
                arrows: false,
                pagination: false,
                autoplay: true,
                interval: 2500,
                pauseOnHover: true
            }
        }
    },
    components: {
        Splide,
        SplideSlide,
    }
}
</script>