<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="header-pagination">
            <RouterLink to="/">Главная</RouterLink>
            <a>Отзывы</a>
        </div>
        <div class="review-page">
            <h1 class="title d-flex justify-content-between align-items-center">Отзывы</h1>
            <div class="row">
                <Splide :options="options">
                    <SplideSlide class="col-12 col-md-6 col-lg-4" v-for="review in getReviewsList" :key="review">
                        <div class="review-wrap">
                            <div class="review-photo">
                                <img :src="review.photo" :alt="review.fullname">
                            </div>
                            <div class="review-title">
                                <span>{{ review.fullname }}</span>
                                <p class="review-text">{{ review.message }}</p>
                                <small class="review-date">{{ review.date }}</small>
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>
            <div class="py-4"></div>
            <div class="row">
                <div class="col-md-5">
                    <h1 class="title">Оcтавить отзыв</h1>
                    <p class="review-footer-text">
                        Мы работаем для вас каждый день улучшая свой сервис!
                        Мы будем очень рады если вы оставите нам свой честный
                        отзыв! Мы всегда готовы меняться и работать для вашего удобства
                    </p>
                </div>
                <div class="col-md-6">
                    <Form v-on:submit="onsubmit" :validation-schema="validate" ref="form" class="form" autocomplete="off">
                        <div class="form-group">
                            <Field v-slot="{ field }" v-model="message" name="message">
                                <textarea v-bind="field" class="form-input" rows="6" name="message" placeholder="Ваш отзыв" v-model="message" required></textarea>
                            </Field>
                        </div>
                        <div class="review-form">
                            <div class="error-input-wrap">
                                <ErrorMessage class="error-wrap" name="message" />
                            </div>
                            <button class="button" type="submit" :disabled="loading">Отправить</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            // fullname: '',
            // email: '',
            message: '',
            options: {
                // type: 'loop',
                perPage: 3,
                gap: '20px',
                arrows: true,
                pagination: false,
                autoplay: true,
                interval: 2500,
                pauseOnHover: true,
                keyboard: 'global',
                breakpoints: {
                    1366: {
                        perPage: 3,
                    },
                    1200: {
                        perPage: 2,
                    },
                    992: {
                        perPage: 2,
                    },
                    768: {
                        perPage: 1,
                    },
                },
            }
        }
    },
    components: {
        Splide,
        SplideSlide,
        Form,
        Field,
        ErrorMessage,
        Header,
        Footer
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        validate() {
            return yup.object({
                // fullname: yup.string().required('Поле обязательное для заполнения'),
                // email: yup.string().required('Поле обязательное для заполнения').email('Неправильный адрес электронной почты'),
                message: yup.string().required('Поле обязательное для заполнения')
            })
        },
        getReviewsList() {
            return (this.$store.getters.getReviewsList)
                ? this.$store.getters.getReviewsList
                : this.$store.dispatch('getReviewsList')
        }
    },
    methods: {
        onsubmit() {
            if (this.$refs.form.validate()) {
                const data = {
                    // fullname: this.fullname,
                    // email: this.email,
                    message: this.message
                }
                this.$store.dispatch('addReview', data)
            }
        }
    }
}
</script>