<template>
    <div class="container z-index auth-wrap">
        <div class="row">
            <div class="col-md-5 auth-form">
                <h1 class="title">Добро пожаловать</h1>
                <p class="subtitle">Регистрация</p>
                <form v-on:submit.prevent="registration" class="form" autocomplete="off">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label class="form-label">Имя</label>
                            <div class="form-group">
                                <input type="text" class="form-input" v-model="firstname" placeholder="Имя" :disabled="loading" required>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="form-label">Фамилия</label>
                            <div class="form-group">
                                <input type="text" class="form-input" v-model="lastname" placeholder="Фамилия" :disabled="loading" required>
                            </div>
                        </div>
                    </div>
                    <label class="form-label">E-mail</label>
                    <div class="form-group">
                        <input type="email" class="form-input" v-model="email" placeholder="Введите e-mail" :disabled="loading" required>
                    </div>
                    <label class="form-label">Пароль</label>
                    <div class="form-group">
                        <input type="password" class="form-input" v-model="password" placeholder="Введите пароль" :disabled="loading" required>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-input" v-model="passwordRepeat" placeholder="Повторите пароль" :disabled="loading" required>
                    </div>
                    <div class="py-3"></div>
                    <div class="text-center">
                        <button class="button button-default d-inline-block lg" type="submit" :disabled="loading">Регистрация</button>
                        <span class="form-agree">У вас уже есть аккаунт? <router-link to="/auth/login">Войти</router-link></span>
                        <span class="form-agree text">Нажимая на кнопку «Регистрация», Вы принимаете условия <router-link to="/rules">Публичной оферты</router-link></span>
                    </div>
                </form>
            </div>
            <div class="col-md-7 d-flex justify-content-center align-items-center">
                <div class="auth-icon"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            passwordRepeat: ''
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        }
    },
    methods: {
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        registration() {
            if (!this.firstname || this.firstname.trim() === '') {
                this.notification('Введите имя')
            } else {
                if (!this.lastname || this.lastname.trim() === '') {
                    this.notification('Введите Фамилию')
                } else {
                    if (!this.email || this.email.trim() === '') {
                        this.notification('Введите E-mail')
                    } else {
                        if (!this.password || this.password.trim() === '') {
                            this.notification('Введите пароль')
                        } else {
                            if (!this.passwordRepeat || this.passwordRepeat.trim() === '') {
                                this.notification('Повторите пароль')
                            } else {
                                if (this.passwordRepeat !== this.password) {
                                    this.notification('Пароли не совпадают')
                                } else {
                                    const data = {
                                        'firstname': this.firstname,
                                        'lastname': this.lastname,
                                        'email': this.email,
                                        'password': this.password,
                                        'password_confirm': this.passwordRepeat
                                    }
                                    this.$store.dispatch('registration', data)
                                }
                            }
                        }
                    }
                }
            }
        },
    }
}
</script>