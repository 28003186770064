export default {
    state: {
        currencies: '',
        mainBalance: '',
        profile: '',
        actions: ''
    },
    mutations: {
        setCurrencies(state, payload) {
            state.currencies = payload.currencies
            if(payload.total_balance) {
                state.mainBalance = payload.total_balance
            }
        },
        setProfile(state, payload) {
            state.profile = payload.profile
        },
        setActionList(state, payload) {
            state.actions = payload.action
        }
    },
    actions: {
        async getCurrencies({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/currencies'})
            .then(response => response.json())
            .then(data => {
                commit('setCurrencies', data)
            })
        },
        async getProfile({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/profile/personal'})
            .then(response => response.json())
            .then(data => {
                if(data.messages === 'Access denied!') {
                    dispatch('logout')
                } else {
                    commit('setProfile', data)
                }
            })
        },
        async getActionList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/profile/action-log'})
            .then(response => response.json())
            .then(data => {
                commit('setActionList', data)
            })
        }
    },
    getters: {
        getCurrencies(state) {
            return state.currencies
        },
        getMainBalance(state) {
            return state.mainBalance
        },
        getProfile(state) {
            return state.profile
        },
        getActionList(state) {
            return state.actions
        }
    }
}