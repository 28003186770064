<template>
    <div class="left-bar">
        <div class="profile-info" v-if="profile && (profile.firstname || profile.lastname || profile.avatar)" v-on:click="redirectVerification">
            <div class="photo-wrap">
                <img :src="profile?.avatar + '?v=1.0.0'" :alt="profile.email">
            </div>
            <div class="profile-verif-wrap">
                <b>{{ profile?.firstname +' '+ profile?.lastname }}</b>
                <span v-if="profile.verification?.value == 1" class="text-danger">{{ profile.verification.name }}</span>
                <span v-if="profile.verification?.value == 2" class="text-success">{{ profile.verification.name }}</span>
                <span v-if="profile.verification?.value == 3" class="text-warning">{{ profile.verification.name }}</span>
                <span v-if="profile.verification?.value == 4" class="text-danger">{{ profile.verification.name }}</span>
            </div>
        </div>
        <div class="left-bar-balance">
            <span>Ваш баланс:</span>
            <b :class="balance > 0 && 'active'">{{ balance > 0 ? balance : 0 }}<i>USDT</i></b>
        </div>
        <div class="dashboard-main-menu-wrap">
            <div class="dashboard-menu">
                <router-link to="/dashboard/payment-services/259" class="button lg">Вывод на карту</router-link>
                <template v-for="item in menu" :key="item">
                    <span class="dashboard-nav-label" v-if="item.label">{{ item.label }}</span>
                    <router-link :to="item.link" class="dashboard-nav ease" :class="item.icon" v-if="item.disabled !== true">{{ item.name }}</router-link>
                    <a class="dashboard-nav ease disabled" v-else>{{ item.name }}</a>
                </template>
                <a href="#" class="dashboard-nav ease logout" v-on:click="logout">Выйти</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            mainBalance: '',
            menu: [
                {name: 'Главная', icon: 'home', link: '/dashboard'},
                {name: 'Пополнить', icon: 'refill', link: '/dashboard/refill/cryptocurrency'},
                {name: 'Вывести', icon: 'withdraw', link: '/dashboard/withdraw'},
                {name: 'Купить', icon: 'buy', link: '/dashboard/buy'},
                {name: 'Продать', icon: 'sell', link: '/dashboard/sell'},
                {name: 'Курс валют', icon: 'rates', link: '/dashboard/rates'},
                {name: 'История операций', icon: 'history', link: '/dashboard/operations'},
                {name: 'Оплата услуг', icon: 'payment', link: '/dashboard/payment-services'},
                {name: 'Настройки', icon: 'settings', link: '/dashboard/settings'}
            ]
        }
    },
    computed: {
        profile() {
            return (this.$store.getters.getProfile)
            ? this.$store.getters.getProfile
            : this.$store.dispatch('getProfile')
        },
        balance() {
            return (this.$store.getters.getMainBalance)
            ? this.$store.getters.getMainBalance
            : this.$store.dispatch('getCurrencies')
        }
    },
    watch: {
        // balance() {
        //     this.mainBalance = this.balance.find((el) => {
        //         return el.currency == 'USDT'
        //     })
        // }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        redirectVerification() {
            if(this.profile.verification?.value !== '2') {
                this.$router.push('/dashboard/settings/verification')
            }
        }
    }
}
</script>