<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="header-pagination">
            <RouterLink to="/">Главная</RouterLink>
            <a>Скачать мобильное приложение</a>
        </div>
        <div class="mobile-app-page">
            <h1 class="title text-center py-4">{{ text }}</h1>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            text: 'Redirecting ...'
        }
    },
    components: {
        Header,
        Footer
    },
    created() {
        if(this.getMobileOperatingSystem() == 'Android') {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.navisdevs.xbt'
        }
        if(this.getMobileOperatingSystem() == 'iOS') {
            window.location.href = 'https://apps.apple.com/kg/app/xbt-app/id6503434077'
        }
        if(this.getMobileOperatingSystem() == 'Windows Phone' || this.getMobileOperatingSystem() == 'unknown') {
            this.text = 'The device is not supported'
        }
    },
    methods: {
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone"
            }
            if (/android/i.test(userAgent)) {
                return "Android"
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS"
            }
            return "unknown"
        }
    }
}
</script>