<template>
    <div class="container z-index auth-wrap">
        <div class="row">
            <div class="col-md-5 auth-form">
                <h1 class="title">Добро пожаловать</h1>
                <p class="subtitle">Вход в личный кабинет</p>
                <form v-on:submit.prevent="login" class="form" autocomplete="off">
                    <div class="form-group">
                        <input type="email" class="form-input" v-model="email" placeholder="Ваш e-mail" :disabled="loading" required>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-input" v-model="password" placeholder="Введите пароль" :disabled="loading" required>
                    </div>
                    <template v-if="twofactor">
                        <div class="form-group">
                            <input type="number" class="form-input" ref="code" placeholder="Введите защитный код" :disabled="loading" required>
                        </div>
                    </template>
                    <div class="py-3"></div>
                    <div class="text-center">
                        <button class="button button-default d-inline-block lg" type="submit" :disabled="loading">Войти</button>
                        <span class="form-agree"><router-link to="/auth/restore">Забыли пароль?</router-link></span>
                    </div>
                </form>
            </div>
            <div class="col-md-7 d-flex justify-content-center align-items-center">
                <div class="auth-icon"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: ''
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        twofactor() {
            return this.$store.getters.twofactor
        }
    },
    methods: {
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        login() {
            if (!this.email || this.email.trim() === '') {
                this.notification('Введите E-mail')
            } else {
                if (!this.password || this.password.trim() === '') {
                    this.notification('Введите пароль')
                } else {
                    const data = {
                        'email': this.email,
                        'password': this.password,
                    }
                    if(this.twofactor) {
                        data['2fa_otp'] = String(this.$refs.code.value)
                    }
                    this.$store.dispatch('login', data)
                }
            }
        }
    }
}
</script>