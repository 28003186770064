import router from "@/router"

export default {
    state: {
        passwordStatus: '',
        country: '',
        security: '',
        generate2fa: ''
    },
    mutations: {
        setPasswordStatus(state, payload) {
            state.passwordStatus = payload
        },
        setCountry(state, payload) {
            state.country = payload.countries
        },
        setSecurity(state, payload) {
            state.security = payload.security
        },
        setGenerate2fa(state, payload) {
            state.generate2fa = payload.security
        }
    },
    actions: {
        async updateProfilePhoto({dispatch, commit}, data) {
            let formData = new FormData()
            formData.append('photo', data.photo)
            dispatch('sendFileRequest', {endpoint: '/profile/personal/edit', formData})
            .then(response => response.data)
            .then(data => {
                if(data.response === true) {
                    dispatch('getProfile')
                    commit('notification', {message: data.messages, type: 'success'})
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async updateProfileInfo({dispatch, commit}, formData) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/profile/personal/edit-profile', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    if(formData.photo && formData.photo !== undefined) {
                        dispatch('updateProfilePhoto', formData)
                    } else {
                        dispatch('getProfile')
                        commit('notification', {message: data.messages, type: 'success'})
                    }
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async changePassword({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/profile/password', data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    commit('setPasswordStatus', true)
                    commit('notification', {message: data.messages, type: 'success'})
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async getCountry({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/countries'})
            .then(response => response.json())
            .then(data => {
                if(data.countries && data.countries !== undefined) {
                    commit('setCountry', data)
                }
            })
        },
        async verification({dispatch, commit}, formData) {
            dispatch('sendFileRequest', {endpoint: '/profile/verification', formData})
            .then(response => response.data)
            .then(data => {
                if(data.response === true) {
                    dispatch('getProfile')
                    commit('notification', {message: data.messages, type: 'success'})
                } else {
                    commit('notification', {message: data.messages})
                }
            })
            .catch((error) => {
                commit('notification', {message: error.response.data.messages})
            })
        },
        async getSecurity({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/profile/security/parameters'})
            .then(response => response.json())
            .then(data => {
                if(data.security && data.security !== undefined) {
                    commit('setSecurity', data)
                } else {
                    commit('setSecurity', '')
                }
            })
        },
        async generate2fa({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/profile/security/2fa/generate'})
            .then(response => response.json())
            .then(data => {
                if(data.security && data.security !== undefined) {
                    commit('setGenerate2fa', data)
                }
            })
        },
        async enable2fa({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/profile/security/2fa/enable/', data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    dispatch('getProfile')
                    router.push('/dashboard/settings/security')
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async disable2fa({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/profile/security/2fa/disable/', data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    dispatch('getProfile')
                    router.push('/dashboard/settings/security')
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
    },
    getters: {
        passwordStatus(state) {
            return state.passwordStatus
        },
        country(state) {
            return state.country
        },
        security(state) {
            return state.security
        },
        generate2fa(state) {
            return state.generate2fa
        }
    }
}