<template>
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            <div class="dashboard-personal-wrap border-0">
                <form v-on:submit.prevent="onsubmit" class="form" autocomplete="off">
                    <label class="form-label">Текущий пароль</label>
                    <div class="form-group mb-4">
                        <input type="password" name="current-password" class="form-input" placeholder="Введите текущий пароль" v-model="password" required :disabled="loading">
                    </div>
                    <label class="form-label">Новый пароль</label>
                    <div class="form-group mb-4">
                        <input type="password" name="new-password" class="form-input" placeholder="Введите новый пароль" v-model="NewPassword" required :disabled="loading">
                    </div>
                    <label class="form-label">Повторите пароль</label>
                    <div class="form-group mb-4">
                        <input type="password" name="repeat-password" class="form-input" placeholder="Повторите новый пароль" v-model="RepeatPassword" required :disabled="loading">
                    </div>
                    <button class="button block lg mt-4" type="submit" :disabled="loading">Сохранить</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            password: '',
            NewPassword: '',
            RepeatPassword: ''
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        status() {
            return this.$store.getters.passwordStatus
        }
    },
    watch: {
        status() {
            this.password = ''
            this.NewPassword = ''
            this.RepeatPassword = ''
        }
    },
    methods: {
        onsubmit() {
            if(!this.password || String(this.password).trim() === '') {
                this.$store.dispatch('notification', {message: 'Введите текущий пароль'})
            } else {
                if(!this.NewPassword || String(this.NewPassword).trim() === '') {
                    this.$store.dispatch('notification', {message: 'Введите новый пароль'})
                } else {
                    if(!this.RepeatPassword || String(this.RepeatPassword).trim() === '') {
                        this.$store.dispatch('notification', {message: 'Повторите новый пароль'})
                    } else {
                        if(this.NewPassword !== this.RepeatPassword) {
                            this.$store.dispatch('notification', {message: 'Пароли не совпадают'})
                        } else {
                            const data = {
                                'old_password': this.password,
                                'new_password': this.NewPassword,
                                'confirm_password': this.RepeatPassword
                            }
                            this.$store.dispatch('changePassword', data)
                        }
                    }
                }
            }
        }
    }
}
</script>