<template>
    <div class="dashboard-wrap">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-8">
                <h1 class="dashboard-title">
                    <span>Ваши кошельки</span>
                    <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
                </h1>
                <div class="dashboard-personal-wrap p-0">
                    <div class="main-balance-wrap" v-for="item in balances" :key="item">
                        <div class="main-balance-name">
                            <img :src="item.logo" :alt="item.name">
                            <div>
                                <span>{{ item.currency }}</span>
                                <b>{{ item.name }}</b>
                            </div>
                        </div>
                        <div class="main-balance-value">
                            <span>{{ item.balance }}</span>
                            <b :class="item.converted_balance > 0 ? 'text-success' : 'text-danger'">${{ item.converted_balance }}</b>
                        </div>
                        <div class="main-balance-action">
                            <router-link :to="'/dashboard/refill/cryptocurrency?currency=' + item.currency" v-if="item.can_deposit === true">Ввод</router-link>
                            <a class="disabled" v-else>Ввод</a>
                            <router-link :to="'/dashboard/withdraw/detail/' + item.currency" v-if="item.can_withdraw === true">Вывод</router-link>
                            <a class="disabled" v-else>Вывод</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-4">
                <div class="row">
                    <div class="col-lg-6 col-xl-12">
                        <h1 class="dashboard-title">Ваши данные</h1>
                        <div class="dashboard-personal-wrap" v-if="profile">
                            <h1 class="title">Лимиты за текущий день</h1>
                            <div class="profile-info-column icon-none">
                                <div>
                                    <span>Пополнение</span>
                                    <b>{{ profile.limits?.refill }} USDT</b>
                                </div>
                            </div>
                            <div class="profile-info-column icon-none">
                                <div>
                                    <span>Покупка</span>
                                    <b>{{ profile.limits?.buy }} USDT</b>
                                </div>
                            </div>
                            <div class="profile-info-column icon-none">
                                <div>
                                    <span>Продажа</span>
                                    <b>{{ profile.limits?.sell }} USDT</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-12">
                        <div class="dashboard-personal-wrap" v-if="profile">
                            <h1 class="title">Персональные данные</h1>
                            <div class="profile-info-column calendar">
                                <div>
                                    <span>Дата регистрации</span>
                                    <b>{{ profile.date_register }}</b>
                                </div>
                            </div>
                            <div class="profile-info-column exit">
                                <div>
                                    <span>Последний вход</span>
                                    <b>{{ profile.last_access }}</b>
                                </div>
                            </div>
                            <div class="profile-info-column email">
                                <div>
                                    <span>Ваша почта</span>
                                    <b>{{ profile.email }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        balances() {
            return (this.$store.getters.getCurrencies)
            ? this.$store.getters.getCurrencies
            : this.$store.dispatch('getCurrencies')
        },
        profile() {
            return (this.$store.getters.getProfile)
            ? this.$store.getters.getProfile
            : this.$store.dispatch('getProfile')
        },
        actions() {
            return (this.$store.getters.getActionList)
            ? this.$store.getters.getActionList
            : this.$store.dispatch('getActionList')
        }
    },
}
</script>