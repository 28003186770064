<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="header-pagination">
            <RouterLink to="/">Главная</RouterLink>
            <a>{{ NewsDetail.name }}</a>
        </div>
        <div class="news-page z-index">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-12">
                    <div class="news-detail-wrap" v-if="NewsDetail">
                        <div class="news-detail-info">
                            <span class="news-detail-title">{{ NewsDetail.name }}</span>
                            <p class="news-description" v-html="NewsDetail.body"></p>
                        </div>
                        <div class="news-thumbnail">
                            <img :src="NewsDetail.photo" :alt="NewsDetail.name">
                        </div>
                    </div>
                    <div class="news-detail-static">
                        <i class="calendar">{{ NewsDetail.date }}</i>
                        <i class="view">{{ NewsDetail.views }}</i>
                    </div>
                </div>
            </div>
            <div class="row news-footer-info">
                <div class="col-md">
                    <p>
                        Обменять криптовалюту в Оше и по всему Кыргызстану теперь не проблема! Ош — криптовалютная столица
                        Ферганской долины и купить или продать USDT здесь теперь здесь очень просто! на XBT.KG МБАНК, Бакай
                        Банк, любой банк Кыргызстана доступен для обмена криптовалют.
                    </p>
                </div>
                <div class="col-md">
                    <p>
                        Мы работаем для вас каждый день улучшая свой сервис!
                        Следите за нашими новостями в социальных сетях, на
                        нашем сайте и в приложении.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            NewsId: ''
        }
    },
    components: {
        Header,
        Footer
    },
    created() {
        if (this.$router.currentRoute.value.params.id) {
            this.$store.dispatch('getNewsDetail', this.$router.currentRoute.value.params.id)
        }
    },
    computed: {
        NewsDetail() {
            return this.$store.getters.getNewsDetail
        }
    }
}
</script>