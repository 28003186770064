<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">
            <span>История операций</span>
            <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
        </h1>
        <div class="dashboard-table table-main">
            <div class="row dashboard-table-row table-header h">
                <div class="col-xl-2 dashboard-table-column"></div>
                <div class="col-xl-3 dashboard-table-column">Дата</div>
                <div class="col-xl-2 dashboard-table-column">Тип операции</div>
                <div class="col-xl-2 dashboard-table-column">Сумма</div>
                <div class="col-xl-3 dashboard-table-column">Статус</div>
            </div>
            <template v-if="history">
                <div class="row dashboard-table-row nth md his" v-for="item in history" :key="item">
                    <div class="col-2 col-md-2 col-xl-2 dashboard-table-column">
                        <img :src="item.logo" :alt="item.slug">
                    </div>
                    <div class="col-xl-3 dashboard-table-column h">{{ item.date +' '+ item.time }}</div>
                    <div class="col-4 col-md-4 col-xl-2 dashboard-table-column his-col">
                        <span class="link" v-on:click="showDetail(item)" v-if="item.status.value == 2">{{ item.type }}</span>
                        <span v-else>{{ item.type }}</span>
                        <span class="md-block his-date">{{ item.date +' '+ item.time }}</span>
                    </div>
                    <div class="col-xl-2 dashboard-table-column h">
                        <span v-if="item.operation_type == '1'">{{ item.debit }} {{ item.currency }}</span>
                        <span v-else-if="item.operation_type == '2'">{{ item.credit }} {{ item.currency }}</span>
                        <span v-else-if="item.operation_type == '3'">{{ item.sum }} {{ item.currency }}</span>
                        <span v-else-if="item.operation_type == '4'">{{ item.sum }} {{ item.currency }}</span>
                        <span v-else-if="item.operation_type == '5'">{{ item.debit }} {{ item.currency }}</span>
                        <span v-else-if="item.operation_type == '6'">{{ item.sum }} {{ item.currency }}</span>
                        <span v-else-if="item.operation_type == '7'">{{ item.sum }} {{ item.currency }}</span>
                        <span v-else-if="item.operation_type == '8'">{{ item.sum }} KGS</span>
                        <span v-else-if="item.operation_type == '9'">{{ item.debit }} {{ item.currency }}</span>
                        <span v-else>{{ item.sum }} {{ item.currency }}</span>
                    </div>
                    <div class="col-6 col-md-6 col-xl-3 dashboard-table-column his-col his-just-end">
                        <span class="md-block">{{ item.sum }} {{ item.currency }}</span>
                        <span v-if="item.status.value == 1" class="his-status text-warning">{{ item.status.name }}</span>
                        <span v-else-if="item.status.value == 2" class="his-status text-success">{{ item.status.name }}</span>
                        <span v-else-if="item.status.value == 3" class="his-status text-danger">{{ item.status.name }}</span>
                        <span v-else class="his-status">{{ item.status.name }}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <span class="icon-text empty pb-5">Нет данных</span>
            </template>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-content">
            <h1 class="modal-title text-center mb-3">Квитанция о платеже</h1>
            <div class="dashboard-table px-0">
                <div class="dashboard-table-row table-row-v2 md">
                    <div class="dashboard-table-column">Дата</div>
                    <div class="dashboard-table-column">{{ detail.date +' '+ detail.time }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2 md">
                    <div class="dashboard-table-column">Тип операции</div>
                    <div class="dashboard-table-column">{{ detail.type }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2 md" v-if="detail.sum">
                    <div class="dashboard-table-column">Сумма</div>
                    <div class="dashboard-table-column">{{ detail.sum }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2 md" v-if="detail.credit">
                    <div class="dashboard-table-column">Сумма к списанию</div>
                    <div class="dashboard-table-column">{{ detail.credit }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2 md" v-if="detail.debit">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ detail.debit }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2 md" v-if="detail.commission">
                    <div class="dashboard-table-column">Комиссия</div>
                    <div class="dashboard-table-column">{{ detail.commission }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2 md" v-if="detail.currency">
                    <div class="dashboard-table-column">Валюта</div>
                    <div class="dashboard-table-column">{{ detail.currency }}</div>
                </div>
            </div>
            <div class="form-group mb-2" v-if="detail.batch">
                <label class="form-label">Номер квитанции</label>
                <input type="text" class="form-input" :value="detail.batch" readonly>
            </div>
            <div class="form-group mb-2" v-if="detail.requisite">
                <label class="form-label">Реквизит</label>
                <input type="text" class="form-input" :value="detail.requisite" readonly>
            </div>
            <div class="form-group mb-2" v-if="detail.memo">
                <label class="form-label">Примечание</label>
                <input type="text" class="form-input" :value="detail.memo" readonly>
            </div>
            <button class="button block lg mt-2" v-on:click="closeModal">Закрыть</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalStatus: false,
            detail: '',
        }
    },
    created() {
        this.$store.dispatch('getHistory')
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        history() {
            return this.$store.getters.history
        }
    },
    methods: {
        showModal() {
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
        },
        showDetail(item) {
            this.detail = item
            this.showModal()
        },
    }
}
</script>