<template>
    <div class="container z-index auth-wrap">
        <div class="row">
            <div class="col-md-5 auth-form">
                <h1 class="title">Подтверждение</h1>
                <p class="subtitle">Добро пожаловать</p>
                <p class="text text-center">{{ message }}</p>
                <router-link to="/auth/login" class="button button-default d-inline-block lg">Продолжить</router-link>
            </div>
            <div class="col-md-7 d-flex justify-content-center align-items-center">
                <div class="auth-icon"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            guid: '',
            message: 'Вы можете войти в систему, используя свой адрес электронной почты и пароль.'
        }
    },
    mounted() {
        this.guid = this.$router.currentRoute.value.params.guid
        if(this.guid !== undefined && this.guid !== '') {
            this.$store.dispatch('confirmationAccount', this.guid)
        } else {
            this.$router.push('/auth/login')
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        getActivationResult() {
            return this.$store.getters.getActivationResult
        },
        getActivationMessage() {
            return this.$store.getters.getActivationMassage
        }
    },
    watch: {
        getActivationMessage() {
            // this.message = this.getActivationMessage
        }
    },
    methods: {
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        confirmationAccount() {
            this.$store.dispatch('activation', this.guid)
        }
    }
}
</script>