<template>
    <section class="features">
        <div class="container">
            <h1 class="title text-center">Наши преимущества</h1>
            <p class="subtitle text-center max-width-60 d-center">Наше видение заключается в продвижении свободы денег. Мы верим, что, продвигая эту свободу, мы можем повлиять на качество жизни по всему миру.</p>
            <div class="row features-container">
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-wallet"></i>
                            <span class="features-name">Разнообразие способов оплаты</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-license"></i>
                            <span class="features-name">Наличие лицензии</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-free"></i>
                            <span class="features-name">Прозрачные комиссии</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-security"></i>
                            <span class="features-name">Безопасность пользователей</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>