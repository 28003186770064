<template>
    <section class="faq">
        <div class="container">
            <h1 class="title text-center pb-4">Часто задаваемые вопросы</h1>
            <div class="faq-wrap" v-if="FAQ.faq">
                <template v-for="(faq, index) in FAQ.faq[category]" :key="index++">
                    <div class="faq-box" :class="index == faqIndex && 'active'" v-on:click="open(index)">
                        <span>{{ faq.question }}</span>
                        <p v-html="faq.answer"></p>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            faqIndex: 0,
            category: ''
        }
    },
    created() {
        if (this.$store.getters.getMainFAQ) {
            this.category = Object.keys(this.$store.getters.getMainFAQ.faq)[0]
        }
    },
    computed: {
        FAQ() {
            return (this.$store.getters.getMainFAQ)
                ? this.$store.getters.getMainFAQ
                : this.$store.dispatch('getMainFAQ')
        }
    },
    watch: {
        FAQ() {
            this.category = Object.keys(this.FAQ.faq)[0]
        }
    },
    methods: {
        open(index) {
            this.faqIndex = this.faqIndex == index ? 0 : index
        }
    }
}
</script>