<template>
    <section class="news">
        <div class="container">
            <div class="news-wrapper" v-if="news">
                <div class="news-wrapper-text">
                    <h1 class="title mb-4">Последние новости</h1>
                    <p class="news-wrap-subtitle">Наше видение заключается в продвижении свободы денег. Мы верим, что, продвигая эту свободу, мы можем повлиять на качество жизни по всему миру.</p>
                    <router-link to="/news" class="button lg">Смотреть все</router-link>
                </div>
                <div class="news-wrapper-side">
                    <div class="main-news">
                        <div class="main-news-wrap" v-for="item in news.news" :key="item">
                            <router-link :to="'/news/' + item.id" class="news-card news-second">
                                <div class="main-news-thumbnail">
                                    <img :src="item.photo" :alt="item.name">
                                </div>
                                <span>{{ item.name }}</span>
                                <p>{{ item.date }}</p>
                            </router-link>
                        </div>
                        <div class="main-news-wrap" v-for="item in news.news" :key="item">
                            <router-link :to="'/news/' + item.id" class="news-card news-second">
                                <div class="main-news-thumbnail">
                                    <img :src="item.photo" :alt="item.name">
                                </div>
                                <span>{{ item.name }}</span>
                                <p>{{ item.date }}</p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        news() {
            return this.$store.getters.getMainData
        }
    }
}
</script>