export default {
    state: {
        FAQList: '' 
    },
    mutations: {
        setFAQ(state, payload) {
            state.FAQList = payload.faq
        }
    },
    actions: {
        async getFAQList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/faq'})
            .then(response => response.json())
            .then(data => {
                commit('setFAQ', data)
            })
        }
    },
    getters: {
        getFAQList(state) {
            return state.FAQList
        }
    }
}