<template>
    <div class="row m-order" v-if="profile">
        <template v-if="profile.verification.value !== '2'">
            <span class="icon-text times" v-if="profile.verification.value == '4'">{{ profile.verification.name }}</span>
            <div class="iframe-verification">
                <iframe :src="'https://api.xbt.kg/ru/sumsub/widget?token=' + token" allow="camera; microphone; geolocation"></iframe>
            </div>
        </template>
    </div>
    <template v-if="profile && profile.verification.value == '2'">
        <div class="dashboard-personal-wrap">
            <span class="icon-text v2 my-4 check">{{ profile.verification.name }}</span>
        </div>
    </template>
</template>

<script>
export default {
    data() {
        return {
            token: ''
        }
    },
    created() {
        this.token = localStorage.getItem('token')
    },
    computed: {
        profile() {
            return this.$store.getters.getProfile
        }
    }
}
</script>