<template>
    <div class="header" :class="String(this.$router.currentRoute.value.path).search('dashboard') !== -1 ? 'active' : ''">
        <div class="container" :class="menuStatus && 'z-index'">
            <div class="header-menu">
                <div class="mobile-menu" v-on:click="showMenu" :class="menuStatus && 'open'">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <router-link to="/" class="logo"></router-link>
                <div class="header-nav" :class="menuStatus && 'active'">
                    <router-link to="/" class="menu">
                        <b>Главная</b>
                    </router-link>
                    <router-link to="/about" class="menu">
                        <b>О компании</b>
                    </router-link>
                    <router-link to="/news" class="menu">
                        <b>Новости</b>
                    </router-link>
                    <router-link to="/reviews" class="menu">
                        <b>Отзывы</b>
                    </router-link>
                    <router-link to="/faq" class="menu">
                        <b>FAQ</b>
                    </router-link>
                    <router-link to="/contacts" class="menu">
                        <b>Контакты</b>
                    </router-link>
                    <router-link to="/auth/login" class="auth-menu ml-auto" v-if="!isAuth">Войти</router-link>
                    <router-link to="/auth/register" class="auth-menu active" v-if="!isAuth">Регистрация</router-link>
                    <a class="auth-menu ml-auto" v-if="isAuth" v-on:click="logout">Выйти</a>
                    <router-link to="/dashboard" class="auth-menu active" v-if="isAuth">Мой кабинет</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAuth: false,
            menuStatus: false
        }
    },
    created() {
        if (localStorage.getItem('isAuth') === 'true' && localStorage.getItem('token')) {
            this.isAuth = true
        }
    },
    computed: {
        balance() {
            return (this.$store.getters.getCurrencies)
                ? this.$store.getters.getCurrencies
                : this.$store.dispatch('getCurrencies')
        }
    },
    watch: {
        $route() {
            this.menuStatus = false
        }
    },
    methods: {
        showMenu() {
            this.menuStatus = (this.menuStatus) ? false : true
        },
        logout() {
            this.$store.dispatch('logout')
            this.isAuth = false
        }
    }
}
</script>