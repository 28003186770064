<template>
    <div class="col-12 col-md-12 col-lg-10 col-xl-10">
        <div class="dashboard-personal-wrap setting-profile-wrap">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                    <form v-on:submit.prevent="onsubmit" class="form px-4" autocomplete="off">
                        <label class="profile-photo">
                            <input type="file" class="d-none" @change="avatarPreview">
                            <img v-if="photoPreview" :src="photoPreview">
                            <span>Обновить фото профиля</span>
                        </label>
                        <div class="form-group mb-4">
                            <input type="text" name="fisrtname" class="form-input" v-model="firstname" required :disabled="loading">
                        </div>
                        <div class="form-group mb-4">
                            <input type="text" name="lastname" class="form-input" v-model="lastname" required :disabled="loading">
                        </div>
                        <div class="form-group mb-4">
                            <input type="text" class="form-input" :value="profile.email" disabled>
                        </div>
                        <div class="form-group mb-4">
                            <input type="text" class="form-input" :value="profile.phone" disabled>
                        </div>
                        <div class="text-center">
                            <button class="button button-default d-inline-block lg mt-2" type="submit" :disabled="loading">Сохранить</button>
                        </div>
                    </form>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                    <form v-on:submit.prevent="updatePassword" class="form px-4" autocomplete="off">
                        <router-link to="/dashboard/settings/verification">
                            <img :src="VerificationIcon" alt="XBT Verification" class="setting-profile-img">
                        </router-link>
                        <div class="form-group mb-4">
                            <input type="password" name="current-password" class="form-input" placeholder="Введите текущий пароль" v-model="password" required :disabled="loading">
                        </div>
                        <div class="form-group mb-4">
                            <input type="password" name="new-password" class="form-input" placeholder="Введите новый пароль" v-model="NewPassword" required :disabled="loading">
                        </div>
                        <div class="form-group mb-4">
                            <input type="password" name="repeat-password" class="form-input" placeholder="Повторите новый пароль" v-model="RepeatPassword" required :disabled="loading">
                        </div>
                        <div class="text-center">
                            <button class="button button-default d-inline-block lg mt-2" type="submit" :disabled="loading">Сохранить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VerificationIcon from '../../assets/img/banner.svg'

export default {
    data() {
        return {
            VerificationIcon: VerificationIcon,
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            photo: '',
            photoPreview: '',
            password: '',
            NewPassword: '',
            RepeatPassword: ''
        }
    },
    created() {
        if(this.profile) {
            this.firstname = this.profile.firstname
            this.lastname = this.profile.lastname
            this.photoPreview = this.profile.avatar
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        profile() {
            return this.$store.getters.getProfile
        },
        status() {
            return this.$store.getters.passwordStatus
        }
    },
    watch: {
        profile() {
            if(this.profile) {
                this.firstname = this.profile.firstname
                this.lastname = this.profile.lastname
                this.photoPreview = this.profile.avatar
            }
        },
        status() {
            this.password = ''
            this.NewPassword = ''
            this.RepeatPassword = ''
        }
    },
    methods: {
        avatarPreview(e) {
            this.photo = e.target.files[0]
            this.photoPreview = URL.createObjectURL(this.photo)
        },
        onsubmit() {
            if(!this.firstname || this.firstname === '') {
                this.$store.dispatch('notification', {message: 'Введите имя'})
            } else {
                if(!this.lastname || this.lastname === '') {
                    this.$store.dispatch('notification', {message: 'Введите фамилию'})
                } else {
                    const data = {
                        firstname: this.firstname,
                        lastname: this.lastname,
                        photo: this.photo
                    }
                    this.$store.dispatch('updateProfileInfo', data)
                }
            }
        },
        updatePassword() {
            if(!this.password || String(this.password).trim() === '') {
                this.$store.dispatch('notification', {message: 'Введите текущий пароль'})
            } else {
                if(!this.NewPassword || String(this.NewPassword).trim() === '') {
                    this.$store.dispatch('notification', {message: 'Введите новый пароль'})
                } else {
                    if(!this.RepeatPassword || String(this.RepeatPassword).trim() === '') {
                        this.$store.dispatch('notification', {message: 'Повторите новый пароль'})
                    } else {
                        if(this.NewPassword !== this.RepeatPassword) {
                            this.$store.dispatch('notification', {message: 'Пароли не совпадают'})
                        } else {
                            const data = {
                                'old_password': this.password,
                                'new_password': this.NewPassword,
                                'confirm_password': this.RepeatPassword
                            }
                            this.$store.dispatch('changePassword', data)
                        }
                    }
                }
            }
        }
    }
}
</script>