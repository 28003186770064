<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="header-pagination">
            <RouterLink to="/">Главная</RouterLink>
            <a>Политика конфиденциальности</a>
        </div>
        <div class="py-3">
            <div v-html="page.detail?.content"></div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Footer
    },
    created() {
        this.$store.dispatch('getPageContent', 'privacy-policy')
    },
    computed: {
        page() {
            return this.$store.getters.getPageContent 
        }
    }
}
</script>