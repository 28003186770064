export default {
    state: {
        Main: '',
        FAQ: '',
        Contacts: '',
        PageContent: ''
    },
    mutations: {
        setMain(state, payload) {
            state.Main = payload
        },
        setFAQ(state, payload) {
            state.FAQ = payload
        },
        setContacts(state, payload) {
            state.Contacts = payload
        },
        setPageContent(state, payload) {
            state.PageContent = payload
        }
    },
    actions: {
        async getMainData({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/index'})
            .then(response => response.json())
            .then(data => {
                commit('setMain', data)
            })
        },
        async getMainFAQ({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/faq'})
            .then(response => response.json())
            .then(data => {
                commit('setFAQ', data)
            })
        },
        async getContacts({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/pages/contacts'})
            .then(response => response.json())
            .then(data => {
                commit('setContacts', data)
            })
        },
        async getPageContent({dispatch, commit}, page = '') {
            dispatch('sendRequest', {method: 'GET', endpoint: '/pages/' + page})
            .then(response => response.json())
            .then(data => {
                commit('setPageContent', data)
            })
        }
    },
    getters: {
        getMainData(state) {
            return state.Main
        },
        getMainFAQ(state) {
            return state.FAQ
        },
        getContacts(state) {
            return state.Contacts
        },
        getPageContent(state) {
            return state.PageContent
        }
    }
}