<template>
    <section class="rates">
        <div class="container">
            <h1 class="title text-center pb-3">Курсы криптовалют</h1>
            <div class="row">
                <template v-for="(item, index) in getMainData.currencies" :key="index">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="index++<=4">
                        <div class="rate-wrap">
                            <div class="rate-wrap-header">
                                <img :src="item.logo" :alt="item.name">
                                <span>{{ item.name }}</span>
                            </div>
                            <div class="rate-wrap-content">
                                <h1><b>$</b>{{ item.rate }}</h1>
                                <span :class="String(item.difference).search('-') !== -1 ? 'text-danger' : 'text-success'">{{ item.difference }}$</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    computed: {
        getMainData() {
            return (this.$store.getters.getMainData)
                ? this.$store.getters.getMainData
                : this.$store.dispatch('getMainData')
        }
    },
    methods: {
        rateFormat(data) {
            const rates = []
            Object(data.rates).forEach((item, index) => {
                rates[index++] = item.price
            })
            return [{data: rates}]
        }
    }
}
</script>