<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="header-pagination">
            <RouterLink to="/">Главная</RouterLink>
            <a>Новости</a>
        </div>
        <div class="news-page">
            <h1 class="title">Последние новости</h1>
            <div class="col-md-5 col-lg-5 col-xl-5">
                <div class="form-group">
                    <input type="search" class="form-input search-input" v-model="searchValue" placeholder="Поиск ...">
                </div>
            </div>
            <div class="row">
                <template v-for="item in NewsList.news" :key="item">
                    <div class="main-news-wrap mb-4" v-if="search(item)">
                        <router-link :to="'/news/' + item.id" class="news-card news-second">
                            <div class="main-news-thumbnail">
                                <img :src="item.photo" :alt="item.name">
                            </div>
                            <span>{{ item.name }}</span>
                            <p>{{ item.date }}</p>
                        </router-link>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            searchValue: ''
        }
    },
    components: {
        Header,
        Footer
    },
    computed: {
        NewsList() {
            return (this.$store.getters.getNewsList)
                ? this.$store.getters.getNewsList
                : this.$store.dispatch('getNewsList')
        }
    },
    methods: {
        search(item) {
            return (item.name.toLowerCase().search(this.searchValue.toLowerCase()) !== -1 || item.body.toLowerCase().search(this.searchValue.toLowerCase()) !== -1) ? true : false
        }
    }
}
</script>