<template>
    <section class="slider">
        <div class="container slider-main">
            <div class="slider-main-wrap">
                <h1 class="slider-main-h1">XBT.kg</h1>
                <span class="slider-main-span">- Идеальный способ купить или продать криптовалюту не выходя из дома</span>
                <div class="px-3 mb-3 md-block">
                    <router-link to="/auth/login" class="button default lg block" v-if="!isAuth">Войти в личный кабинет</router-link>
                    <router-link to="/dashboard" class="button default lg block" v-else>Личный кабинет</router-link>
                </div>
                <p class="slider-main-p">Прозрачные комиссии, наличие лицензии, большие резервы и отличная репутация гарантируют успех ваших обменных операций!</p>
                <div class="slide-device-image"></div>
                <div class="slider-qr-wrap md-flex">
                    <div class="slider-qr-image">
                        <img :src="appQRcode" alt="QR-code">
                    </div>
                    <div class="slider-qr-link">
                        <p>Отсканируйте QR-код или перейдите по ссылке ниже чтобы загрузить приложение:</p>
                        <a :href="appLink" target="_blank">IOS и Android</a>
                    </div>
                </div>
                <div class="slider-qr-wrap v2 dd-flex">
                    <div class="slider-qr-image">
                        <img :src="downloadQRCode" alt="QR-code">
                    </div>
                    <div class="slider-qr-link">
                        <p class="pb-2">Отсканируйте QR-код, чтобы загрузить приложение </p>
                        <a href="#" class="text-decoration-none">IOS и Android</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DownloadQRCode from '../../assets/img/qr-code.svg'
import AndroidQRCode from '../../assets/img/qr-code-android-v1.svg'
import IOSQRCode from '../../assets/img/qr-code-ios-v1.jpeg'

export default {
    data() {
        return {
            isAuth: localStorage.getItem('token'),
            appLink: '',
            appQRcode: '',
            downloadQRCode: DownloadQRCode,
        }
    },
    created() {
        if(this.getMobileOperatingSystem() == 'Android') {
            this.appQRcode = AndroidQRCode
            this.appLink = 'https://play.google.com/store/apps/details?id=com.navisdevs.xbt'
        }
        if(this.getMobileOperatingSystem() == 'iOS') {
            this.appQRcode = IOSQRCode
            this.appLink = 'https://apps.apple.com/kg/app/xbt-app/id6503434077'
        }
    },
    methods: {
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone"
            }
            if (/android/i.test(userAgent)) {
                return "Android"
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS"
            }
            return "unknown"
        }
    }
}
</script>