export default {
    state: {
        twofactor: false
    },
    mutations: {
        twofactor(state, payload) {
            state.twofactor = payload
        }
    },
    actions: {
        twofactor({commit}, status) {
            commit('twofactor', status)
        }
    },
    getters: {
        twofactor(state) {
            return state.twofactor
        }
    }
}