<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="header-pagination">
            <RouterLink to="/">Главная</RouterLink>
            <a>Контакты</a>
        </div>
        <div class="contacts-page">
            <div class="contacts">
                <div class="contact_form">
                    <div v-html="contacts.detail?.content"></div>
                    <div>
                        <img :src="License" alt="XBT License">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import License from '@/assets/img/license.png'

export default {
    data() {
        return {
            License: License
        }
    },
    components: {
        Header,
        Footer
    },
    computed: {
        contacts() {
            return (this.$store.getters.getContacts)
                ? this.$store.getters.getContacts
                : this.$store.dispatch('getContacts')
        }
    }
}
</script>