<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">
            <span>Настройки</span>
            <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
        </h1>
    </div>
    <div class="tab-arrow">
        <div class="tab horizontal account default-color">
            <router-link to="/dashboard/settings" class="tab-button">Персональные данные</router-link>
            <router-link to="/dashboard/settings/verification" class="tab-button">Верификация</router-link>
            <router-link to="/dashboard/settings/security" class="tab-button">Безопасноть</router-link>
        </div>
    </div>
    <div class="dashboard-wrap">
        <router-view></router-view>
    </div>
</template>