<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="header-pagination">
            <RouterLink to="/">Главная</RouterLink>
            <a>Ошибка 404</a>
        </div>
        <div class="page-not-found text-center">
            <h1 class="title text-center py-4">Страница не найдена</h1>
            <p class="text text-center">Неправильно набран адрес, или такой страницы на сайте больше не существует</p>
            <router-link to="/" className="button button-default d-inline-block lg">На главную страницу</router-link>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Footer
    }
}
</script>