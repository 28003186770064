<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">Купить {{ detail?.name }}</h1>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 col-xl-6">
                <div class="row" v-if="info">
                    <div class="col-12 col-md-12">
                        <div class="dashboard-personal-wrap border-0">
                            <div class="profile-info-column mt-0 icon-none">
                                <div>
                                    <span>Курс</span>
                                    <b>1.00 {{ currency }} = {{ detail.rate }} USDT</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="dashboard-personal-wrap border-0">
                            <div class="profile-info-column mt-0 icon-none">
                                <div>
                                    <span>Минимальная сумма</span>
                                    <b>{{ info.min_qty }} {{ currency }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="dashboard-personal-wrap border-0">
                            <div class="profile-info-column mt-0 icon-none">
                                <div>
                                    <span>Комиссия</span>
                                    <b>{{ info.commission }}%</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form v-on:submit.prevent="onsubmit" class="form" autocomplete="off">
                    <label class="form-label d-flex justify-content-between">Сумма к списанию <span>Баланс: <b class="max-balance" v-on:click="setMaxAmount">{{ balance }}</b> USDT</span></label>
                    <div class="form-group mb-4 position-relative">
                        <input type="number" name="amount" class="form-input" step="any" placeholder="Введите сумму" v-model="amount" @input="calculate" required :disabled="loading">
                        <span class="form-icon icon-max-amount ease" v-on:click="setMaxAmount"></span>
                    </div>
                    <label class="form-label d-flex justify-content-between">Сумма к зачислению <span>Баланс: <b>{{ detail.balance }}</b> {{ currency }}</span></label>
                    <div class="form-group">
                        <input type="text" name="total" class="form-input" placeholder="Сумма к зачислению" :value="quantity" disabled>
                    </div>
                    <button class="button block lg my-4" :disabled="loading">Продолжить</button>
                </form>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-xl-6">
                <div class="dashboard-personal-wrap">
                    <WithdrawText />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-content">
            <h1 class="modal-title text-center mb-3">Подтверждение платежа</h1>
            <div class="dashboard-table px-0">
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к списанию</div>
                    <div class="dashboard-table-column">{{ confirm?.amount }} USDT</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ confirm?.debit }} {{ confirm?.currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Комиссия</div>
                    <div class="dashboard-table-column">{{ confirm?.commission }} USDT</div>
                </div>
            </div>
            <button class="button block lg mt-4" :disabled="loading" v-on:click="confirmBuy">Подтвердить</button>
        </div>
    </div>
</template>

<script>
import WithdrawText from '@/components/Account/WithdrawText'

export default {
    data() {
        return {
            modalStatus: false,
            currency: '',
            balance: '',
            amount: '',
            quantity: ''
        }
    },
    components: {
        WithdrawText
    },
    created() {
        this.$store.dispatch('getProfile')
        this.currency = this.$router.currentRoute.value.params.currency
        this.$store.dispatch('getBuyDetail', this.currency)
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        profile() {
            return this.$store.getters.getProfile
        },
        detail() {
            return (this.$store.getters.getCurrencies)
            ? this.filterCurrency(this.$store.getters.getCurrencies, this.currency)
            : this.$store.dispatch('getCurrencies')
        },
        info() {
            return this.$store.getters.getBuyDetail
        },
        confirm() {
            return this.$store.getters.getBuyConfirm
        }
    },
    watch: {
        detail() {
            if(this.detail.can_buy !== true) {
                this.$router.push('/dashboard/buy')
            } else {
                this.balance = this.filterCurrency(this.$store.getters.getCurrencies, 'USDT').balance
            }
        },
        confirm() {
            this.showModal()
        },
        profile() {
            if (this.profile.verification.value !== '2') {
                this.$router.push('/dashboard/settings/verification')
                this.$store.dispatch('notification', { message: 'Требуется проверка KYC' })
            }
        }
    },
    methods: {
        filterCurrency(list, currency) {
            return list.find((el) => { return el.currency == currency })
        },
        showModal() {
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
        },
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        numberFormat(number, float) {
            var string, result
            if(String(number).includes('.')) {
                string = String(number).split('.')
                result = (float > 0) ? string[0] +'.'+ string[1].substring(0, float) : string[0]
            } else {
                result = number
            }
            return result
        },
        calculate() {
            const commission = (this.amount * this.info.commission) / 100
            const result = this.numberFormat(((this.amount - commission) / this.detail.rate), this.detail.decimal)
            this.quantity = (result >= 0) ? result : parseFloat(0)
        },
        setMaxAmount() {
            this.amount = this.balance
            this.calculate()
        },
        onsubmit() {
            if(this.quantity < parseFloat(this.info.min_qty)) {
                this.notification('Минимальная сумма ' + this.info.min_qty +' '+ this.currency)
            } else {
                const data = {
                    sum: this.amount,
                    currency: this.currency
                }
                this.$store.dispatch('createBuy', data)
            }
        },
        confirmBuy() {
            this.$store.dispatch('confirmBuy', this.confirm.id)
        }
    }
}
</script>