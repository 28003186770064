import { createWebHistory, createRouter } from 'vue-router';
import AuthGuard from './auth-guard'

// Main pages
import Main from '@/pages/Main/Main'
import About from '@/pages/Main/About'
import News from '@/pages/Main/News'
import NewsDetail from '@/pages/Main/NewsDetail'
import FAQ from '@/pages/Main/FAQ'
import Contacts from '@/pages/Main/Contacts'
import Reviews from '@/pages/Main/Reviews'
import MobileApp from '@/pages/Main/MobileApp'
import Rules from '@/pages/Main/Rules'
import PrivacyPolicy from '@/pages/Main/PrivacyPolicy'
import PageNotFound from '@/pages/Main/PageNotFound'

// Auth pages
import Auth from '@/pages/Auth/Auth'
import Login from '@/pages/Auth/Login'
import Registration from '@/pages/Auth/Registration'
import Activation from '@/pages/Auth/Activation'
import ConfirmationAccount from '@/pages/Auth/ConfirmationAccount'
import Restore from '@/pages/Auth/Restore'

// Account pages
import DashboardMain from '@/pages/Account/DashboardMain'
import Dashboard from '@/pages/Account/Dashboard'
import Refill from '@/pages/Account/Refill'
import Buy from '@/pages/Account/Buy'
import BuyDetail from '@/pages/Account/BuyDetail'
import Sell from '@/pages/Account/Sell'
import SellDetail from '@/pages/Account/SellDetail'
import Withdraw from '@/pages/Account/Withdraw'
import WithdrawDetail from '@/pages/Account/WithdrawDetail'
import History from '@/pages/Account/History'
import Rates from '@/pages/Account/Rates'
import PaymentServices from '@/pages/Account/PaymentServices'
import PaymentCategories from '@/pages/Account/PaymentCategories'
import PaymentDetail from '@/pages/Account/PaymentDetail'
import Settings from '@/pages/Account/Settings'
import SettingProfile from '@/pages/Account/SettingProfile'
import SettingPassword from '@/pages/Account/SettingPassword'
import SettingVerification from '@/pages/Account/SettingVerification'
import SettingSecurity from '@/pages/Account/SettingSecurity'
import Security2fa from '@/pages/Account/Security2fa'

const routes = [
    {
        name: 'Main',
        path: '/',
        component: Main
    },
    {
        name: 'About',
        path: '/about',
        component: About
    },
    {
        name: 'News',
        path: '/news',
        component: News
    },
    {
        name: 'News Detail',
        path: '/news/:id',
        component: NewsDetail
    },
    {
        name: 'FAQ',
        path: '/faq',
        component: FAQ
    },
    {
        name: 'Contacts',
        path: '/contacts',
        component: Contacts
    },
    {
        name: 'Reviews',
        path: '/reviews',
        component: Reviews
    },
    {
        name: 'Mobile App Download',
        path: '/mobile-app',
        component: MobileApp
    },
    {
        name: 'Rules',
        path: '/rules',
        component: Rules
    },
    {
        name: 'Privacy Policy',
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        name: 'Auth',
        path: '/auth',
        component: Auth,
        children: [
            {
                name: 'Login',
                path: '/auth/login',
                component: Login
            },
            {
                name: 'Registration',
                path: '/auth/register',
                component: Registration
            },
            {
                name: 'Activation Account',
                path: '/auth/activation/:email',
                component: Activation
            },
            {
                name: 'Confirmation Account',
                path: '/auth/confirm/:guid',
                component: ConfirmationAccount
            },
            {
                name: 'Restore',
                path: '/auth/restore',
                component: Restore
            }
        ]
    },
    {
        name: 'Dashboard Main',
        path: '/dashboard',
        component: DashboardMain,
        beforeEnter: AuthGuard,
        children: [
            {
                name: 'Dashboard',
                path: '/dashboard',
                component: Dashboard
            },
            {
                name: 'Refill',
                path: '/dashboard/refill',
                component: Refill
            },
            {
                name: 'Refill Category',
                path: '/dashboard/refill/:category',
                component: Refill
            },
            {
                name: 'Buy',
                path: '/dashboard/buy',
                component: Buy
            },
            {
                name: 'Buy Detail',
                path: '/dashboard/buy/:currency',
                component: BuyDetail
            },
            {
                name: 'Sell',
                path: '/dashboard/sell',
                component: Sell
            },
            {
                name: 'Sell Detail',
                path: '/dashboard/sell/:currency',
                component: SellDetail
            },
            {
                name: 'Withdraw',
                path: '/dashboard/withdraw',
                component: Withdraw
            },
            {
                name: 'Withdraw Category',
                path: '/dashboard/withdraw/:category',
                component: Withdraw
            },
            {
                name: 'Withdraw Detail',
                path: '/dashboard/withdraw/detail/:currency',
                component: WithdrawDetail
            },
            {
                name: 'History',
                path: '/dashboard/operations',
                component: History
            },
            {
                name: 'Rates',
                path: '/dashboard/rates',
                component: Rates
            },
            {
                name: 'Payment Services',
                path: '/dashboard/payment-services',
                component: PaymentServices
            },
            {
                name: 'Payment Categories',
                path: '/dashboard/payment-services/:category',
                component: PaymentCategories
            },
            {
                name: 'Payment Detail',
                path: '/dashboard/payment-services/:category/:id',
                component: PaymentDetail
            },
            {
                name: 'Settings',
                path: '/dashboard/settings',
                component: Settings,
                children: [
                    {
                        name: 'Profile',
                        path: '/dashboard/settings',
                        component: SettingProfile
                    },
                    {
                        name: 'Change Password',
                        path: '/dashboard/settings/password',
                        component: SettingPassword
                    },
                    {
                        name: 'Verification',
                        path: '/dashboard/settings/verification',
                        component: SettingVerification
                    },
                    {
                        name: 'Security',
                        path: '/dashboard/settings/security',
                        component: SettingSecurity
                    },
                    {
                        name: 'Security 2Fa',
                        path: '/dashboard/settings/security/2fa',
                        component: Security2fa,
                    }
                ]
            }
        ]
    },
    {
        name: 'Page not found',
        path: '/:pathMatch(.*)*',
        component: PageNotFound
    }
]

const router = createRouter({
    mode: 'hash',
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    }
})

export default router