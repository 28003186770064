<template>
    <Header></Header>
    <div class="container">
        <div class="min-height padding-top-default about-page about-ellipse pb-0">
            <div class="row">
                <div class="col-12 col-md-5 col-lg-5 col-xl-5">
                    <h1 class="title"><span class="text-success">Xbt.kg - надежный</span> сервис для обмена криптовалюты!
                    </h1>
                    <p class="about-text">XBT.kg — это сервис отвечающий даже самым серьезным запросам! XBT не просто
                        обменка, а самое главное дело в нашей жизни, нам действительно важен каждый наш клиент.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row about-features">
            <div class="col-12 col-md-6 col-lg-3">
                <div class="features-wrap">
                    <div class="features-title">
                        <i class="features-icon x-icon-wallet"></i>
                        <span class="features-name">Разнообразие способов оплаты</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class="features-wrap">
                    <div class="features-title">
                        <i class="features-icon x-icon-license"></i>
                        <span class="features-name">Наличие лицензии</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class="features-wrap">
                    <div class="features-title">
                        <i class="features-icon x-icon-free"></i>
                        <span class="features-name">Прозрачные комиссии</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class="features-wrap">
                    <div class="features-title">
                        <i class="features-icon x-icon-security"></i>
                        <span class="features-name">Безопасность пользователей</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Partners></Partners>
    <div class="container">
        <div class="about-page">
            <div class="row">
                <div class="col-12 col-md-5 col-lg-5 col-xl-5 d-flex align-items-center">
                    <div class="about-info">
                        <p class="about-text">
                            Вы сможете пополнить баланс вашего аккаунта из любого, даже самого отдаленного места в Кыргызстане,
                            а с помощью банковского перевода вы сможете пополнить свой аккаунт или вывести деньги в любую страну
                            мира. Прозрачные комиссии, наличие лицензии, большие резервы и отличная репутация гарантируют успех
                            ваших обменных операций!
                        </p>
                        <div class="slider-qr-wrap">
                            <div class="slider-qr-image">
                                <img src="../../assets/img/qr-code-android-v1.svg" alt="QR-code">
                            </div>
                            <div class="slider-qr-link">
                                <p>Отсканируйте, чтобы загрузить приложение</p>
                                <a href="#">IOS и Android</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 col-lg-7 col-xl-7 d-flex justify-content-center">
                    <img src="../../assets/img/about-phone.png" alt="Phone">
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Partners from '@/components/Main/Partners.vue'

export default {
    components: {
        Header,
        Footer,
        Partners
    }
}
</script>