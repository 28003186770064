import router from "@/router"

export default {
    state: {
        withdrawCashList: '',
        withdrawDetail: '',
        withdrawConfirm: ''
    },
    mutations: {
        setWithdrawCashList(state, payload) {
            state.withdrawCashList = payload.list
        },
        setWithdrawDetail(state, payload) {
            state.withdrawDetail = payload.parameters
        },
        setWithdrawConfirm(state, payload) {
            state.withdrawConfirm = payload.confirm
        }
    },
    actions: {
        async getWithdrawCashList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/cashout/cash-list'})
            .then(response => response.json())
            .then(data => {
                commit('setWithdrawCashList', data)
            })
        },
        async getWithdrawDetail({dispatch, commit}, currency) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/cashout/parameters/' + currency})
            .then(response => response.json())
            .then(data => {
                commit('setWithdrawDetail', data)
            })
        },
        async createWithdraw({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/cashout/create', data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    commit('setWithdrawConfirm', data)
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async confirmWithdraw({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/cashout/confirm/' + data.guid, data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    dispatch('getCurrencies')
                    router.push('/dashboard/operations')
                } else {
                    if(data.security === '2fa') {
                        commit('twofactor', true)
                    }
                    commit('notification', {message: data.messages})
                }
            })
        }
    },
    getters: {
        getWithdrawCashList(state) {
            return state.withdrawCashList
        },
        getWithdrawDetail(state) {
            return state.withdrawDetail
        },
        getWithdrawConfirm(state) {
            return state.withdrawConfirm
        }
    }
}