<template>
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6" v-if="security">
            <div class="dashboard-personal-wrap" v-if="security['2fa']">
            <h1 class="modal-title text-center">Двухфакторная аутентификация</h1>
                <p class="modal-subtitle text-center">Зайдите в свой аунтефикатор и введите полученный код</p>
                <form v-on:submit.prevent="disable" class="form" autocomplete="off">
                    <label class="form-label">Защитный код</label>
                    <div class="form-group">
                        <input type="number" name="code" class="form-input" placeholder="Введите защитный код" ref="code" :disabled="loading" required>
                    </div>
                    <button class="button button-danger block lg mt-4" type="submit" :disabled="loading">Отключить</button>
                </form>
            </div>
            <div class="dashboard-personal-wrap" v-else>
                <h1 class="modal-title text-center mb-3">Двухфакторная авторизация</h1>
                <span class="modal-subtitle text-center">Загрузите приложение Google Authenticator с Play Market или App Store. Далее, в мобильном приложении отсканируйте этот QR-код.</span>
                <form v-on:submit.prevent="enable" class="form" autocomplete="off">
                    <div class="modal-qr-image">
                        <img :src="generate.qr_code" alt="Google 2fa" class="rounded-0">
                    </div>
                    <p class="text">Если Ваше мобильное приложение не поддерживает QR-коды, введите следующий код:</p>
                    <label class="form-label">Секретный ключ</label>
                    <div class="form-group">
                        <input type="text" class="form-input" v-model="secret" readonly>
                    </div>
                    <label class="form-label">Защитный код</label>
                    <div class="form-group">
                        <input type="number" name="code" class="form-input" placeholder="Введите защитный код" ref="code" :disabled="loading" required>
                    </div>
                    <button class="button button-success block lg mt-4" type="submit" :disabled="loading">Включить</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            secret: ''
        }
    },
    created() {
        if(this.$store.getters.getProfile.security
        && this.$store.getters.getProfile.security['2fa'] === false
        && !this.$store.getters.generate2fa) {
            this.$store.dispatch('generate2fa')
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        security() {
            return this.$store.getters.getProfile.security
        },
        generate() {
           return this.$store.getters.generate2fa
        }
    },
    watch: {
        security() {
            if(this.$store.getters.getProfile.security
            && this.$store.getters.getProfile.security['2fa'] === false
            && !this.$store.getters.generate2fa) {
                this.$store.dispatch('generate2fa')
            }
        },
        generate() {
            this.secret = this.generate.secret
        }
    },
    methods: {
        enable() {
            const data = {
                '2fa_otp': String(this.$refs.code.value),
                '2fa_secret': String(this.secret)
            }
            this.$store.dispatch('enable2fa', data)
        },
        disable() {
            this.$store.dispatch('disable2fa', {'2fa_otp': String(this.$refs.code.value)})
        }
    }
}
</script>